import React, { useState, useRef, Fragment, useEffect } from 'react';

import '../styles/main.css';
import Items from './items';
import Modal from './modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Main = () => {
    const [search_item, SetSearch_item] = useState(undefined);
    const [UsersItems, SetUsersItems] = useState(null);
    const [modal_status, Setmodal_status] = useState(false);
    const [modal_item, Setmodal_item] = useState({items: [], id: -1});

    return (
        <main>
            <ToastContainer autoClose={2000} position='top-left' draggablePercent={40}/>
            <Modal modal_status={modal_status} set_status={Setmodal_status} info={modal_item} Items={UsersItems} SetItems={SetUsersItems}/>
            <div className='all-items'>
                <Items item_name={search_item} set_modal_status={Setmodal_status} set_modal_info={Setmodal_item} SetSearch_item={SetSearch_item} Items={UsersItems} SetItems={SetUsersItems}/>
            </div>
        </main>
    )
}

export default Main;