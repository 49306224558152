import React, { useEffect, useRef } from 'react';

import '../styles/footer.css';

const Footer = () => {
    return (
        <footer>
            Site not affiliated with Valve Corp. 
        </footer>
    )
}

export default Footer;