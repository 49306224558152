import React, { useEffect, useRef } from 'react';

import '../styles/search.css';
import search_icon from "../images/search_icon.png"
import clear_icon from "../images/clear.png"

const Search = ({search_item, SetSearch_item}) => {    
    return (
        <div className='search_area'>
            <img className='search_icon' src={search_icon} /><input id="search_text" type="text" placeholder='Найти' defaultValue={search_item} onChange={(e) => SetSearch_item(e.target.value)}></input>{search_item !== undefined ? <img className='clear_icon' src={clear_icon} onClick={(e) => {SetSearch_item();document.querySelector("#search_text").value = ""}}/> : ""}
        </div>
    )
}

export default Search;