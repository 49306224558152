import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Switch, Link, useLocation } from 'react-router-dom';

import '../styles/header.css';
import burger_piece from "../images/burger_piece.png"
import classes from '../styles/header.module.css';

const Header = () => {
    let location = useLocation();
    
    const [SidebarStatus, SetSidebarStatus] = useState("");
    let sidebar_status = false
    let touch_start = 0
    let touch_direction = ""
    let modal_status = false;

    function Change_Position(e) {
        if (modal_status) {
            return;
        }
        if (touch_direction === "") {
            if (touch_start !== e.changedTouches[0].clientX && (window.innerWidth - touch_start < 20)) {
                touch_direction = "left";
                console.log(touch_direction);
            } else if (touch_start !== e.changedTouches[0].clientX && (window.innerWidth - (window.innerWidth - touch_start) < 20)) { 
                touch_direction = "right";
                console.log(touch_direction);
            } else {touch_direction = "up/down";
                console.log(touch_direction)
            }
        }

        if (touch_direction === "left" && sidebar_status === false) {
            document.querySelector(".sidebar").style.transform = `translateX(calc(100% - ${touch_start - e.changedTouches[0].clientX}px))`;
            if (window.scrollY > 40) {
                document.querySelector("header").style.top = `-${40*Number(e.changedTouches[0].clientX/window.innerWidth, window.innerWidth, e.changedTouches[0].clientX)}px`;
            }
            e.preventDefault();
        } else if (touch_direction === "right" && sidebar_status === true) {
            document.querySelector(".sidebar").style.transform = `translateX(${e.changedTouches[0].clientX - touch_start}px)`;
            if (window.scrollY > 40) {
                document.querySelector("header").style.top = `-${40*Number(e.changedTouches[0].clientX/window.innerWidth, window.innerWidth, e.changedTouches[0].clientX)}px`;
            }
            e.preventDefault();
        }
        if (sidebar_status) {
            e.preventDefault();
        }
    }
    useEffect(() => {
        document.addEventListener('touchmove', Change_Position, {passive: false});
        document.addEventListener('touchstart', function(e) {
            touch_start = e.changedTouches[0].clientX
            if (window.innerWidth - touch_start < 20 && (touch_direction === "left")) {
                document.body.style.overflow = 'hidden';
            }
            if (document.querySelector(`[class='modal']`)) {
                modal_status = true;
            } else {
                modal_status = false;
            }
        });
        document.addEventListener('touchend', function(e) {
            document.body.style.overflow = 'auto';
            // console.log(window.innerWidth - e.changedTouches[0].clientX);
            if ((window.innerWidth - e.changedTouches[0].clientX >= 60 && sidebar_status === false && touch_direction === "left") || (window.innerWidth - (window.innerWidth - e.changedTouches[0].clientX) < 60 && sidebar_status === true && touch_direction === "right")) {
                ChangeSidebarStatus(true);
                SetSidebarStatus(true);
                sidebar_status = true;
            } else if ((window.innerWidth - e.changedTouches[0].clientX < 60 && sidebar_status === false && touch_direction === "left") || (window.innerWidth - (window.innerWidth - e.changedTouches[0].clientX) >= 60 && sidebar_status === true && touch_direction === "right")) {
                ChangeSidebarStatus(false);
                SetSidebarStatus(false);
                sidebar_status = false;
            }
            touch_direction = "";
        });
        document.querySelector(".burger").addEventListener("click", function(e) {
            if (document.querySelector(".burger").getAttribute("class") === "burger") {
                ChangeSidebarStatus(true);
            } else ChangeSidebarStatus(!sidebar_status);
        });
        // document.querySelectorAll("a").addEventListener("click", function(e) {
        //     ChangeSidebarStatus(false);
        // });
        document.querySelectorAll('a').forEach(item => {
            item.addEventListener('click', function(e) {
                ChangeSidebarStatus(false);
            })
          })
        document.addEventListener("scroll", function(e) {
            if (window.scrollY > 40) {
                document.querySelector("header").style.position = "fixed";
                document.querySelector("header").style.top = "-40px";
            } else {
                document.querySelector("header").style.position = "absolute";
                document.querySelector("header").style.top = "0px";
            }
        });
    }, []);

    function ChangeSidebarStatus(stat) {
        if (stat) {
            console.log("opening");
            document.body.style.overflow = 'hidden';
            document.querySelector(".sidebar").setAttribute("style","transition: 0.3s");
            document.querySelector(".sidebar").style.transform = `translateX(0px)`;
            SetSidebarStatus(true);
            sidebar_status = true;
            setTimeout(() => {document.querySelector(".sidebar").style.removeProperty("transition");document.querySelector("header").style.removeProperty("transition");}, 300);
            document.querySelector("header").style.top = "0px";
            document.querySelector("header").style.transition = "0.3s";
            document.querySelector("header").style.position = "fixed";
        } else {
            console.log("closing");
            document.body.style.overflow = 'auto';
            document.querySelector(".sidebar").setAttribute("style","transition: 0.3s");
            document.querySelector(".sidebar").style.removeProperty("transform");
            SetSidebarStatus(false);
            sidebar_status = false;
            setTimeout(() => {document.querySelector(".sidebar").style.removeProperty("transition");document.querySelector("header").style.removeProperty("transition");}, 300);
            document.querySelector("header").style.transition = "0.3s";
            if (window.scrollY > 40) {
                document.querySelector("header").style.position = "fixed";
                document.querySelector("header").style.top = "-40px";
            } else {
                document.querySelector("header").style.position = "absolute";
                document.querySelector("header").style.top = "0px";
            }
        }
    }
    return (
        <>
        <div className='sidebar'>
            <div className='sidebar_option'>
                <Link to="/" style={location.pathname === "/" ? {color: "white"} : {}} onMouseEnter={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}} onMouseLeave={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}}>Продажа</Link>
                <div style={location.pathname === "/" ? {width: "100%"} : {}}></div>
            </div>
            <div className='sidebar_option'>
                <Link to="/finance" style={location.pathname === "/finance" ? {color: "white"} : {}} onMouseEnter={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}} onMouseLeave={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}}>Финансы</Link>
                <div style={location.pathname === "/finance" ? {width: "100%"} : {}}></div>
            </div>
            <div className='sidebar_option'>
                <Link to="/history" style={location.pathname === "/history" ? {color: "white"} : {}} onMouseEnter={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}} onMouseLeave={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}}>История</Link>
                <div style={location.pathname === "/history" ? {width: "100%"} : {}}></div>
            </div>
            {/* <div className='sidebar_option'>
                <Link to="/my_orders" style={location.pathname === "/test" ? {color: "white"} : {}} onMouseEnter={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}} onMouseLeave={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}}>Мои ордера</Link>
                <div style={location.pathname === "/test" ? {width: "100%"} : {}}></div>
            </div> */}
        </div>
        <header style={{position: "absolute", top: "0px"}}>
            <div className={classes.headerOptions}>
                <div className='sidebar_option'>
                    <Link to="/" style={location.pathname === "/" ? {color: "white"} : {}} onMouseEnter={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}} onMouseLeave={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}}>Продажа</Link>
                    <div style={location.pathname === "/" ? {width: "100%"} : {}}></div>
                </div>
                <div className='sidebar_option'>
                    <Link to="/finance" style={location.pathname === "/finance" ? {color: "white"} : {}} onMouseEnter={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}} onMouseLeave={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}}>Финансы</Link>
                    <div style={location.pathname === "/finance" ? {width: "100%"} : {}}></div>
                </div>
                <div className='sidebar_option'>
                    <Link to="/history" style={location.pathname === "/history" ? {color: "white"} : {}} onMouseEnter={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}} onMouseLeave={(e) => {e.target.parentNode.querySelector("div").classList.toggle("on");e.target.classList.toggle("on")}}>История</Link>
                    <div style={location.pathname === "/history" ? {width: "100%"} : {}}></div>
                </div>
            </div>
            <div className={['burger', SidebarStatus !== "" ? SidebarStatus ? "open" : "close" : ""].join(" ")}>
                <img id="p1" src={burger_piece}/>
                <img id="p2" src={burger_piece}/>
                <img id="p3" src={burger_piece}/>
            </div>
        </header>
        </>
    )
}

export default Header;