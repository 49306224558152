import React, {useState, useEffect} from "react";
import axios from 'axios';
import "../styles/items.css"
import img_arrow from "../images/arrow.png"
import status_up from "../images/status_up.png"
import status_down from "../images/status_down.png"
import status_neutral from "../images/status_neutral.png"
import status_online from "../images/status_online.png"
import status_offline from "../images/status_offline.png"
import img_item_status from "../images/item_status.png"
import reload_icon from "../images/reload_icon.png"
import { ToastContainer, toast } from 'react-toastify';
import Search from './search';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Items = ({item_name, set_modal_status, set_modal_info, SetSearch_item, Items, SetItems}) => {
    const navigate = useNavigate();

    const [host_user, Sethost_user] = useState(0)

    async function loadData() {
        try {
            let r = await fetch(`https://artempos.ru/api/items?key=${Cookies.get("session")}`);
            r = await r.json()
            if (r.status) {
                    SetItems(r.users);
                    Sethost_user(r.host_user);
                } else {
                    navigate('/login', { replace: true });
                }
        } catch (error) {
            toast.error("Не получилось выполнить запрос, ошибка: " + error);
        }
    }

    useEffect(() => {
        loadData();
        setInterval(loadData, 60000);
        window.scrollTo(0, 0);
    }, [])

    function Slider(e) {
        console.log(e.target);
        var height = e.target.parentNode.querySelector(".unavailable-items").scrollHeight;
        if (e.target.parentNode.querySelector(".unavailable-items").style.maxHeight === "") {
            e.target.parentNode.querySelector(".unavailable-items").setAttribute("style",`max-height:${height}px`);
            e.target.classList.toggle("open");
        } else {
            e.target.parentNode.querySelector(".unavailable-items").style.removeProperty("max-height");
            e.target.classList.toggle("open");
        }
        // document.querySelector(".group-items").classList.toggle("open")
    }

    async function Change_item_status(item) {
        const response = await fetch(`https://artempos.ru/api/set_item_status?key=${Cookies.get("session")}&id=${item.id}&status=${item.adaptive_price}`);
        let answer = await response.json();
        if (answer.status) {
            // toast.success(answer.answer)
        } else {
            if (answer.error === "wrong session") {
                navigate('/login', { replace: true });
            } else {
                toast.error(answer.error)
            }
        }
    }

    async function Change_user_status(id, status) {
        const response = await fetch(`https://artempos.ru/api/set_user_status?key=${Cookies.get("session")}&id=${id}&status=${status}`);
        let answer = await response.json();
        if (answer.status) {
            // toast.success(answer.answer)
        } else {
            if (answer.error === "wrong session") {
                navigate('/login', { replace: true });
            } else {
                toast.error(answer.error)
            }
        }
    }

    function Update_inventory(user_id) {
        fetch(`https://artempos.ru/api/update_inventory?key=${Cookies.get("session")}&id=${user_id}`)
        .then((response) => response.json())
        .then((answer) => {
            if (answer.status) {
                // Items[user_id].update_status = true;
                SetItems({
                    ...Items,
                    [user_id]: {
                        ...Items[user_id],
                        update_status: true
                    }
                });
            } else {
                if (answer.error === "wrong session") {
                    navigate('/login', { replace: true });
                } else {
                    toast.error(answer.error)
                }
            }
        })
    }

    // console.log(Items);

    if (Items == null) {
        return (<div>Информация загружается</div>);
    } else {
        let users = [];
        let unavailable_id = 0;
        let users_count = 0
        users = Object.keys(Items).sort((a, b) => Items[a].status===false ? 1 : -1).sort((a, b) => a!==host_user ? 1 : -1).map((key) => {
            users_count += 1;
            let available_count = 0;
            let available_items = Items[key].items.sort((a, b) => a.name>b.name ? 1 : -1).map((item) => {
                if (item_name == undefined || item.name.toLowerCase().includes(item_name.toLowerCase()) || Items[key].name.toLowerCase().includes(item_name.toLowerCase())){
                    if (item.available) {
                        available_count += 1;
                        return (
                        <div className="item_container" key={item.id}>
                            {item.sold ? <div className="item_sold"><p>Продан</p></div> : ""}
                            <div className={"item" + (item.adaptive_price ? "" : " offline-item")} id={item.id} onMouseEnter={(e) => {e.target.classList[0] === "item" ? e.target.parentNode.classList.add("scale") : e.target.parentNode.parentNode.classList.add("scale")}} onMouseLeave={(e) => {e.target.classList[0] === "item" ? e.target.parentNode.classList.remove("scale") : e.target.parentNode.parentNode.classList.remove("scale")}}
                                onClick={(e) => {
                                    document.body.style.overflow = 'hidden';
                                    set_modal_status(true);
                                    let id = e.currentTarget.id;
                                    let user_id = e.currentTarget.parentNode.parentNode.parentNode.id;
                                    set_modal_info({items:Items[key].items, id:id, user_id: user_id});
                                }}>
                                <div className="item_info">
                                    {item.price_USD}$
                                </div>
                                {item.name.split("(")[1] != undefined ? <div className="item_wear">{item.name.split("(")[1].replace(/[^A-Z]+/g, "")}</div> : ""}
                                {item.zero_price != null ? <div className="item_percent">{(item.price_USD*100/item.zero_price-100).toFixed(2)}%</div> : ""}
                                <div className="item_position">
                                    <div style={item.position_USD == 1 ? {color: "rgb(15, 170, 41)"} : {color: "red"}}>{item.position_USD != null ? item.position_USD : 0}<img src ={Date.now()/1000 - item.price_change <= 900 ? status_neutral : item.position_USD === 1 ? status_up : status_down}/></div> <div style={item.position_RUB == 1 ? {color: "rgb(15, 170, 41)", marginLeft: 8} : {color: "red", marginLeft: 8}}>{item.position_RUB != null ? item.position_RUB : 0}<img src ={Date.now()/1000 - item.price_change <= 300 ? status_neutral : item.position_RUB === 1 ? status_up : status_down}/></div>
                                </div>
                                <img src={item.img} alt="item_img"></img>
                            </div> 
                            <div className={"item_status " + (item.adaptive_price ? "online" : "offline")} onClick={(e) => {item.adaptive_price = !item.adaptive_price;SetItems({...Items});Change_item_status(item)}}><img src={img_item_status} /></div>
                        </div>
                    )
                    }
                }
            })
            let unavailable_count = 0;
            let unavailable_items = Items[key].items.sort((a, b) => a.name>b.name ? 1 : -1).map((item) => {
                if (item_name == undefined || item.name.toLowerCase().includes(item_name.toLowerCase()) || Items[key].name.toLowerCase().includes(item_name.toLowerCase())){
                    if (!item.available) {
                        unavailable_count += 1;
                        unavailable_id += 1;
                        return <div className="item unavailable-item" id={unavailable_count} key={unavailable_count} onClick={(e) => {
                            set_modal_status(true);
                            let id = 0
                            let user = 0
                            if (e.target.id == "") {
                                if (e.target.parentNode.id == "") {
                                    id = e.target.parentNode.parentNode.id
                                    user = e.target.parentNode.parentNode.parentNode.parentNode.id
                                } else {
                                    id = e.target.parentNode.id
                                    user = e.target.parentNode.parentNode.parentNode.id
                                }
                            } else {
                                id = e.target.id
                                user = e.target.parentNode.parentNode.id
                            }
                            set_modal_info({items:Items[key].items, id:id})
                            }}>
                        <div className="item_info">{item.sell_price}$</div>
                        {item.name.split("(")[1] != undefined ? <div className="item_wear">{item.name.split("(")[1].replace(/[^A-Z]+/g, "")}</div> : ""}
                        <div className="item_percent">{item.profit}%</div>
                        <img src={item.img}></img>
                    </div> 
            
                    }
                }
            })
            let flag = false;
            available_items.forEach(element => {
                if (element != undefined) {
                    flag = true
                }
            });
            unavailable_items.forEach(element => {
                if (element != undefined) {
                    flag = true
                }
            });
            if (flag || key === host_user) {
                return (
                    <div className="user" id={key} key={key}>
                        <div className="user_header">
                            <img className={["user_reload", Items[key].update_status ? "in_process" : ""].join(" ")} src={reload_icon} onClick={() => {Update_inventory(key)}}/>
                            <p>{Items[key].name}</p>
                            <div className="user_status" onClick={() => {Change_user_status(key, !Items[key].status);Items[key].status = !Items[key].status;SetItems({...Items})}}>
                                <div></div>
                                <img className="user_status_online" style={Items[key].status ? {left: 14} : {left: 0}} src={Items[key].status ? status_online : status_offline}/>
                            </div>
                        </div>
                        {users_count === 1 ? <Search search_item={item_name} SetSearch_item={SetSearch_item} /> : ""}
                        <div className="items-opener">Доступные предметы ({available_count})</div>
                        <div className="group-items">{available_items}</div> 
                        <div className="items-opener" onClick={(e) => {Slider(e)}}>Недоступные предметы ({unavailable_count})<img src={img_arrow}></img></div>
                        <div className="group-items unavailable-items">{unavailable_items}</div> 
                    </div> 
                );
            }
        })
        return users
    }
}

export default Items