import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classes from '../styles/login.module.css';

import InputMask from 'react-input-mask';

const Login = () => {
    const [Code, SetCode] = useState("");
    const [Status, SetStatus] = useState({waitcode: false, left: 0});
    const [MessageBox, SetMessageBox] = useState({status: "good", msg: ""});
    const navigate = useNavigate();

    var regex = /^[0-9]{0,6}$/
    function CheckCodeType(code) {
        if (regex.test(code)) SetCode(code);
        if (code.length === 6) Login(code);
    }
    
    useEffect(() => {
        if (Status.waitcode && Status.left >= 0) {
            // SetStatus(t => {return{...t, waitcode: false}})
            const interval = setInterval(() => {
                SetStatus((t) => {
                    if (t.left > 0) return {...t, left: t.left - 1}
                    else {
                        clearInterval(interval);
                        return {...t, waitcode: false}
                    }
                })
            }, 1000);
        }
      
        // setTimeout(() => clearInterval(interval), Status.left*1000);
        
    }, [Status.waitcode]);

    async function Get_code() {
        const tel = document.getElementById('phone').value.replace("+", "").replaceAll("_", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        console.log(tel);
        if (tel.length !== 11) {
            SetMessageBox({status: "bad", msg: "Введи номер телефона полностью"});
            return;
        }
        const response = await fetch(`https://artempos.ru/api/get_code?tel=${tel}&mode=getcode`);
        let answer = await response.json();
        console.log(answer)
        if (answer.status) {
            document.querySelector(`.${classes.block1}`).setAttribute("style","left: -100%");
            document.querySelector(`.${classes.block2}`).setAttribute("style","left: 0%");
            SetMessageBox({status: "good", msg: ""});
            SetStatus({waitcode: true, left: 60})
            // navigate('/', { replace: true });
        } else {
            SetMessageBox({status: "bad", msg: answer.error});
            if ("timeleft" in answer) {
                console.log("asd")
                SetStatus({waitcode: true, left: answer.timeleft})
            }
        }
    }
    async function Login(code) {
        const tel = document.getElementById('phone').value.replace("+", "").replaceAll("_", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        console.log(`https://artempos.ru/api/login?tel=${tel}&code=${code}`)
        const response = await fetch(`https://artempos.ru/api/login?tel=${tel}&code=${code}`);
        let answer = await response.json();
        console.log(answer)
        if (answer.status) {
            let date = new Date();
            date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
            const expires = date.toUTCString();

            document.cookie = `session=${answer.session};expires=${expires}`
            navigate('/', { replace: true });
        } else {
            SetMessageBox({status: "bad", msg: answer.error});
            if (answer.error === "too early for new code") {
                console.log("asd")
                SetStatus({waitcode: true, left: answer.timeleft})
            }
        }
    }

    return (
        <main>
            <div className={classes.login}>
                <p className={classes.title}>Войти</p>
                <div className={classes.block1}>
                    <InputMask mask="+7(999)999-99-99" id="phone" className={classes.field} placeholder='+7(___)___-__-__' inputMode="decimal"/>
                    <p className={classes.name}>Номер телефона</p>
                    <p className={classes.MessageBox} style={MessageBox.status === "good" ? {color: "rgb(15, 170, 41)"} : {color: "rgb(223, 73, 73)"}}>{MessageBox.msg}</p>
                    <button className={classes.confirm} onClick={Get_code}>Получить код</button>
                </div>
                <div className={classes.block2}>
                    <input className={classes.field} placeholder='123456' inputMode="decimal" value={Code} onChange={(e) => {CheckCodeType(e.target.value)}}/>
                    <p className={classes.name}>Код</p>
                    <p className={classes.MessageBox} style={MessageBox.status === "good" ? {color: "rgb(15, 170, 41)"} : {color: "rgb(223, 73, 73)"}}>{MessageBox.msg}</p>
                    <button className={classes.confirm} style={Status.waitcode === true ? {background: "none", pointerEvents: "none", backgroundColor: "rgb(60, 60, 60)"} : {}} onClick={Get_code}>{Status.waitcode === false ? "Получить код еще раз" : `Осталось ${Status.left} секунд`}</button>
                </div>
            </div>
        </main>
    )
}

export default Login;